type Profile = {
  isLoggedIn: boolean;
  name: string;
  src: string;
  status: 'ACTIVE' | 'TEMP' | '';
};

export const INITIAL_PROFILE: Profile = {
  isLoggedIn: false,
  name: '',
  src: '',
  status: '',
};
