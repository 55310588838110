import { useState, useEffect } from 'react';

const useScrollTop = (currentY: number) => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > currentY) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, [currentY]);

  return {
    scrollToTop,
    showButton,
  };
};

export default useScrollTop;
