import {
  customSentryWithScope,
  handleSentryAsyncError,
} from '@src/utils/error';
import { requestAPI } from '@src/utils/fetch';
import axios from 'axios';
import React from 'react';

const noticeApiUrl = '/api/v1/notice';

const getNoticeAll = async () => {
  try {
    const { data } = await requestAPI().get(`${noticeApiUrl}/all`);
    return data;
  } catch (e: any) {
    customSentryWithScope('GET', '/v1/notice/all', e, 'error');
    throw e;
  }
};

const testSentry = async () => {
  console.log('호출여부판단');
  return handleSentryAsyncError('GET', '/v1/shop/mocik', async () => {
    // const data = await axios.get('/api/mock');
    const data = await axios.get('https://api.ppledev.link/status/405');
    console.log(data, '---sentry STATUS');

    return data;
  });
};

const noticeApiList = {
  getNoticeAll,
  testSentry,
};

export default noticeApiList;
