import * as Sentry from '@sentry/nextjs';
/**
 * 모든 함수는 sentry에 관한 error 함수이다.
 */
export function customSentryException() {
  try {
  } catch (error) {}
}

/**
 * scope단위로 이벤트를 관리하여 데이터를 쌓는 함수이다.
 * @param key Tag key
 * @param value Tag Value
 * @param level error Level
 */
export function customSentryWithScope(
  key: string,
  value: string,
  errorRequest: any,
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
) {
  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setTag(key, value);
    if (level) {
      scope.setLevel(level);
    }
    Sentry.captureException(errorRequest);
  });
}

export const handleSentryAsyncError = async <T>(
  method: string,
  endpoint: string,
  asyncFunction: () => Promise<T>
): Promise<T> => {
  try {
    return await asyncFunction();
  } catch (error) {
    customSentryWithScope(method, endpoint, error, 'error');
    throw error;
  }
};
