import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { devices, theme } from '@src/styles/theme';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import Link from 'next/link';
import CheckAuthSvg from '@src/assets/imgs/checkGreen.svg';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
};

const UnAuthorizedModal = ({
  onClose,
  stopPropagation,
  width,
  height,
}: Props) => {
  return (
    <ModalContainer
      width={480}
      height={210}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        <Header>
          <HeaderTitle fontSize={22} fontWeight={700}>
            로그인이 필요한 기능입니다.
          </HeaderTitle>
        </Header>
        <Header>
          <CheckAuthIcon />
        </Header>
        <Content>
          <div>
            <HeaderDescription>
              <Link
                href={'/auth'}
                onClick={onClose}
                style={{
                  color: theme.commonColor.gray004,
                  textDecoration: 'underline',
                }}
              >
                로그인 하기
              </Link>
            </HeaderDescription>
          </div>
        </Content>
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0 20px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
`;

const HeaderTitle = styled(CMText)`
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;
const HeaderDescription = styled(CMText)`
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;

const CheckAuthIcon = styled(CheckAuthSvg)`
  width: 60px;
  height: 60px;
`;

const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

export default UnAuthorizedModal;
