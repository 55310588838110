import { atom } from 'recoil';
type Modal = {
  id:
    | 'appDown'
    | 'bloodDonationType'
    | 'unAuthorized'
    | 'selectCommunity'
    | 'none'
    | 'selectKaKao'
    | 'point'
    | 'search';
  visible: boolean;
};
export const modalAtom = atom<Modal>({
  key: 'atom/modal',
  default: {
    id: 'none',
    visible: false,
  },
});
