const isProd = process.env.NODE_ENV === 'production';
/**
 * case 1 : isProd 상태이며 pple.link 실서버인경우 실서버 GA 코드 사용
 * case 2 : isProd 상태이며 pple-web-dev.click 개발테스트서버인경우 개발테스트 GA 코드 사용
 * case 3 : localhost:3000 인 only dev는 사용 x
 */
export const GA_TRACKING_ID =
  isProd &&
  typeof window !== 'undefined' &&
  window.location.href.includes('link')
    ? process.env.NEXT_PUBLIC_PROD_GOOGLE_ANALYTICS
    : isProd &&
      typeof window !== 'undefined' &&
      window.location.href.includes('click')
    ? process.env.NEXT_PUBLIC_DEV_GOOGLE_ANALYTICS
    : '';

/**
 *
 * @param url 페이지 경로입니다.
 * @param title 페이지의 제목입니다.
 */
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, title: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
    page_title: title,
  });
};

/**
 *
 * @param action  	Google 애널리틱스 이벤트 보고서에서 이벤트 액션으로 표시될 값입니다.
 * @param category  이벤트의 카테고리입니다.
 * @param label  	이벤트 라벨입니다.
 * @param value  	이벤트 값으로 표시될 음수가 아닌 정수입니다.
 */

interface requestEvent {
  action: string;
  category: string;
  label: string;
  value?: number;
}
// https://developers.google.com/analy`tics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: requestEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export function share(communityType: 'donation' | 'board') {
  if (communityType === 'donation') {
    event({
      action: '공유해서도움주기',
      category: '공유',
      label: '공유해서 도움주기버튼 클릭',
    });
  } else {
    event({
      action: '커뮤니티공유',
      category: '공유',
      label: '공유 버튼 클릭',
    });
  }
}

export function like(communityType: 'donation' | 'board') {
  if (communityType === 'donation') {
    event({
      action: '응원하기',
      category: '좋아요',
      label: '응원하기 버튼 클릭',
    });
  } else {
    event({
      action: '좋아요',
      category: '좋아요',
      label: '좋아요 버튼 클릭',
    });
  }
}

export function signup() {
  event({
    action: '회원가입',
    category: '회원가입',
    label: '회원가입 버튼 클릭',
  });
}

export function download(userAgent?: '' | 'android' | 'ios') {
  if (userAgent === '') {
    event({
      action: '앱 다운로드',
      category: '다운로드',
      label: '다운로드 버튼 클릭',
    });
  } else if (userAgent === 'android') {
    event({
      action: '안드로이드 다운로드',
      category: '다운로드',
      label: '안드로이드 다운로드 버튼 클릭',
    });
  } else {
    event({
      action: 'IOS 다운로드',
      category: '다운로드',
      label: 'IOS 다운로드 버튼 클릭',
    });
  }
}

export function communityInsert() {
  event({
    action: '커뮤니티 글 작성(단순클릭)',
    category: '글 작성',
    label: '커뮤니티 글 작성 버튼 클릭',
  });
}

export function checkCommunityInsert(communityType: 'donation' | 'board') {
  if (communityType === 'donation') {
    event({
      action: '헌혈요청 글 작성',
      category: '헌혈요청 작성',
      label: '헌혈요청 작성 버튼 클릭',
    });
  } else {
    event({
      action: '커뮤니티 글 작성',
      category: '커뮤니티 작성',
      label: '커뮤니티 작성 버튼 클릭',
    });
  }
}

export function checkCommunityCommentInsert(isReply: boolean) {
  if (isReply) {
    event({
      action: '대댓글 작성',
      category: '대댓글 작성',
      label: '대댓글 작성 버튼 클릭',
    });
  } else {
    event({
      action: '댓글 작성',
      category: '댓글 작성',
      label: '댓글 작성 버튼 클릭',
    });
  }
}

export function checkSponserClick() {
  event({
    action: '후원하기',
    category: '후원하기',
    label: '후원 버튼 클릭',
  });
}
