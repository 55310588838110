import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { devices, theme } from '@src/styles/theme';
import Image from 'next/image';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import LogoSvg from '@src/assets/imgs/logo.svg';
import StoreBtn from '@src/components/Molecules/Store/StoreBtn';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
};

const AppDownModal = ({ onClose, stopPropagation, width, height }: Props) => {
  return (
    <ModalContainer
      width={480}
      height={210}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        <Header>
          <HeaderTitle fontSize={22} fontWeight={700}>
            피플 앱으로 열기
          </HeaderTitle>
        </Header>
        <Content>
          <Logo />
          <div
            style={{
              marginLeft: 12,
            }}
          >
            <HeaderDescription>
              <div>모바일에서만 이용할 수 있어요.</div>
              <div>앱을 설치하시면 채팅 기능을 이용할 수 있습니다.</div>
            </HeaderDescription>
            <StoreBtn isColumn={false} isMobileModal={true} />
          </div>
        </Content>
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
`;

const HeaderTitle = styled(CMText)`
  @media ${devices.tablet} {
    font-size: 16px;
  }
`;
const HeaderDescription = styled(CMText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.commonColor.gray005};

  text-align: left;
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;

const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

const Logo = styled(LogoSvg)`
  width: 90px;
  height: 90px;
`;

export default AppDownModal;
