import { atom } from 'recoil';

type Profile = {
  isLoggedIn: boolean;
  name: string;
  src: string;
  status: 'ACTIVE' | 'TEMP' | '';
};
const profileAtom = atom<Profile>({
  key: 'atom/profile',
  default: {
    isLoggedIn: false,
    name: '',
    src: '',
    status: '',
  },
});

export default profileAtom;
