import { requestAPI } from '@src/utils/fetch';

const getCurrent = async () => {
  const { data } = await requestAPI().get(`/api/v2/account/calendar`);
  return data;
};

const getBetweenCalender = async (fromDate: string, toDate: string) => {
  const { data } = await requestAPI().get(
    `/api/v1/account/calendar/between?fromDate=${fromDate}&toDate=${toDate}`
  );
  return data;
};

const calenderApiList = {
  getCurrent,
  getBetweenCalender,
};

export default calenderApiList;
