import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { devices, theme } from '@src/styles/theme';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import Link from 'next/link';
import CheckAuthSvg from '@src/assets/imgs/checkGreen.svg';
import { useRef } from 'react';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
};

const SelectKaKaoModal = ({
  onClose,
  stopPropagation,
  width,
  height,
}: Props) => {
  const linkRef: any = useRef(null);
  const linkRef2: any = useRef(null);
  const handleClick = () => {
    linkRef.current.click();
  };

  const handleClick2 = () => {
    linkRef2.current.click();
  };
  return (
    <ModalContainer
      width={480}
      height={210}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        <Content>
          <HeaderDescription>
            <LinkButton onClick={handleClick}>
              <a
                ref={linkRef}
                href="https://open.kakao.com/o/s2RXJWfd"
                target="_blank"
              >
                피플 고객센터
              </a>
            </LinkButton>
          </HeaderDescription>
          <HeaderDescription>
            <LinkButton onClick={handleClick2}>
              <a
                ref={linkRef2}
                href="https://open.kakao.com/o/gOg74umb"
                target="_blank"
              >
                피플 헌혈자모임 카톡
              </a>
            </LinkButton>
          </HeaderDescription>
        </Content>
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
`;

const HeaderDescription = styled(CMText)`
  color: ${({ theme }) => theme.commonColor.black};
  display: inline-block;
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;

const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

const LinkButton = styled.button`
  padding: 18px 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.commonColor.pink004};
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  @media ${devices.tablet} {
    padding: 6px 8px;
    font-size: 11px;
  }
`;

export default SelectKaKaoModal;
