import CMUserProfile from '@src/components/Atoms/CMUserProfile';
import { INITIAL_PROFILE } from '@src/data/Auth/profile';
import useAuth from '@src/hooks/useAuth';
import useOutsideClick from '@src/hooks/useOutsideClick';
import profileAtom from '@src/recoils/atom/profile';
import { devices } from '@src/styles/theme';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const ProfileWrap = () => {
  const router = useRouter();

  const [profile, setProfile] = useRecoilState(profileAtom);
  const { fetchProfile } = useAuth();
  const { data: userInfo } = fetchProfile();

  const [toggle, setToggle] = useState(false);

  const onToggle = useCallback((value: boolean) => {
    setToggle(value);
  }, []);

  const menuRef = useRef(null);
  useOutsideClick(menuRef, () => setToggle(false));

  const handleLogout = () => {
    deleteCookie('X-AUTH-TOKEN');
    setProfile(INITIAL_PROFILE);
    onToggle(false);
  };

  useEffect(() => {
    if (userInfo) {
      setProfile({
        isLoggedIn: true,
        name: userInfo.displayName,
        src: userInfo.profileImageUrl,
        status: userInfo.status,
      });
    } else setProfile(INITIAL_PROFILE);
  }, [userInfo]);

  return (
    <>
      {profile.isLoggedIn ? (
        <Wrap ref={menuRef} onClick={() => onToggle(!toggle)}>
          <CMUserProfile
            width={devices.tablet ? '35px' : '20px'}
            height={devices.tablet ? '35px' : '20px'}
            borderRadius={devices.tablet ? '35px' : '20px'}
            src={profile.src}
          />
          <ProfileName>{profile.name}님</ProfileName>

          {toggle && (
            <Menu>
              <MenuItem
                onClick={() => {
                  router.push('/my?category=profile');
                }}
              >
                마이페이지
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
              >
                로그아웃
              </MenuItem>
            </Menu>
          )}
        </Wrap>
      ) : (
        <LoginText onClick={() => router.push('/auth')}>
          <DeskTopLoginText>
            <span>로그인</span>
            <Circle />
            <span>회원가입</span>
          </DeskTopLoginText>
          {/* <TabletLoginText>로그인 / 회원가입</TabletLoginText> */}
        </LoginText>
      )}
    </>
  );
};

const DeskTopLoginText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  @media ${devices.tablet} {
    display: none;
  }
`;

const TabletLoginText = styled.div`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.commonColor.gray003};
  width: 4.5px;
  height: 4.5px;
  @media ${devices.tablet} {
    display: none;
  }
`;

const LoginText = styled.span`
  @media ${devices.tablet} {
    display: block;
    color: ${({ theme }) => theme.commonColor.gray006};
    text-decoration: underline;
  }
`;

const ProfileName = styled.div`
  @media ${devices.tablet} {
    font-size: 10px;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  @media ${devices.tablet} {
    gap: 10px;
  }
`;

const Menu = styled.div`
  display: block;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 99999999999;
`;

const MenuItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.commonColor.pink004};
    color: #fff;
  }
  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 10px 10px;
  }
`;

export default ProfileWrap;
