import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { devices, theme } from '@src/styles/theme';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import Link from 'next/link';
import { useState, useCallback, use } from 'react';
import BloodDonationSvg from '@src/assets/imgs/bloodDonation.svg';
import BloodReviewSvg from '@src/assets/imgs/bloodReview.svg';
import FreeSvg from '@src/assets/imgs/free.svg';
import { useBodyScrollLock } from '@src/hooks/useBodyScrollLock';
import SearchModal from './SearchModal';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
  title?: string;
  content?: string;
};

const SelectCommunityModal = ({
  onClose,
  stopPropagation,
  width,
  height,
  title = '',
  content = '',
}: Props) => {
  const [isCategory, setIsCategory] = useState(false);
  const onCategory = useCallback(() => {
    setIsCategory(true);
  }, []);
  const { lockScroll } = useBodyScrollLock();

  const [isDisplay, setIsDisplay] = useState(false);

  return (
    <ModalContainer
      width={480}
      height={380}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        {/* 생활 클릭 아닐경우 */}
        {/* {!isCategory && (
          <>
            <Header>
              <HeaderTitle fontSize={22} fontWeight={700}>
                이야기를 올릴 카테고리를 골라주세요
              </HeaderTitle>
            </Header>
            <Content>
              <div>
                <Link href={'/community/edit?type=donation'} onClick={onClose}>
                  <SelectCategoryWrap
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <CategoryButton>헌혈요청</CategoryButton>
                    <HeaderDescription>
                      헌혈과 관련되어 피플러의 도움이 필요하신가요?
                    </HeaderDescription>
                    <Description>
                      지정헌혈 요청, 헌혈 캠페인 등 헌혈 관련 모든 모집이면
                      좋아요!
                    </Description>
                  </SelectCategoryWrap>
                </Link>
                <SelectCategoryWrap onClick={onCategory}>
                  <CategoryButton>커뮤니티</CategoryButton>
                  <HeaderDescription>
                    나의 이야기를 나누고 싶으신가요?
                  </HeaderDescription>
                  <Description>
                    헌혈 관련 이야기도 좋고, 자유로운 주제의 어떤 이야기도
                    좋아요!
                  </Description>
                </SelectCategoryWrap>
              </div>
            </Content>
          </>
        )} */}
        {/* 생활 클릭  */}
        {
          <>
            <Header>
              <HeaderTitle fontSize={20} fontWeight={700}>
                커뮤니티에 작성할 카테고리를 골라주세요.
              </HeaderTitle>
            </Header>
            <Content>
              <Column>
                <Link
                  href={'/community/edit?type=board&category=BLOOD_HOME_REVIEW'}
                  onClick={onClose}
                >
                  <Flex
                    onClick={() => {
                      lockScroll();
                      setIsDisplay(true);
                    }}
                  >
                    <Item>
                      <BloodDonationSvg />
                    </Item>
                    <Item>헌혈의집 카페리뷰</Item>
                  </Flex>
                </Link>
                <Link
                  href={'/community/edit?type=board&category=QNA'}
                  onClick={onClose}
                >
                  <Flex>
                    <Item>
                      <BloodReviewSvg />
                    </Item>
                    <Item>헌혈정보게시판</Item>
                  </Flex>
                </Link>
                <Link
                  href={'/community/edit?type=board&category=FREE'}
                  onClick={onClose}
                >
                  <Flex>
                    <Item>
                      <FreeSvg />
                    </Item>
                    <Item>일상게시판</Item>
                  </Flex>
                </Link>
              </Column>
            </Content>
          </>
        }
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
`;

const HeaderTitle = styled(CMText)`
  @media ${devices.tablet} {
    font-size: 16px;
  }
`;
const HeaderDescription = styled(CMText)`
  display: block;
  margin: 8px 0px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.6px;
  font-weight: 700;
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.commonColor.gray005};
  @media ${devices.tablet} {
    font-size: 14px;
  }
`;
const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

const SelectCategoryWrap = styled.div`
  background-color: ${({ theme }) => theme.commonColor.gray001};
  border-radius: 8px;
  padding: 12px 14px;
  cursor: pointer;
  width: 360px;
  text-align: left;
  @media ${devices.tablet} {
    width: 240px;
  }
`;

const CategoryButton = styled.button`
  background-color: ${({ theme }) => theme.commonColor.pink001};
  border-radius: 5px;
  color: ${({ theme }) => theme.commonColor.pink004};
  padding: 4px 8px;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Item = styled.div`
  flex: 1;
`;
export default SelectCommunityModal;
