import { devices } from '@src/styles/theme';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import AppleSvg from '@src/assets/imgs/appleIcon.svg';
import PlayStoreSvg from '@src/assets/imgs/playStoreIcon.svg';
import * as gtag from '@src/utils/gtag';
type Props = {
  isColumn?: boolean;
  marginTop?: string;
  width?: number;
  height?: number;
  isMobileColumn?: boolean;
  isMobileModal?: boolean;
};
const StoreBtn = ({
  isColumn,
  marginTop,
  width = 20,
  height = 20,
  isMobileColumn,
  isMobileModal,
}: Props) => {
  return (
    <FlexDiv
      isColumn={isColumn}
      marginTop={marginTop}
      isMobileColumn={isMobileColumn}
    >
      <DownButton
        isColumn={isColumn}
        isMobileColumn={isMobileColumn}
        isMobileModal={isMobileModal}
        onClick={() => {
          gtag.download('ios');
        }}
      >
        <a
          href={'https://apps.apple.com/kr/app/%ED%94%BC%ED%94%8C/id1621815801'}
          target="_blank"
        >
          <AppStoreIcon width={width} height={height} />
          App Store
        </a>
      </DownButton>
      <DownButton
        isColumn={isColumn}
        isMobileColumn={isMobileColumn}
        isMobileModal={isMobileModal}
        onClick={() => {
          gtag.download('android');
        }}
      >
        <a
          href={
            'https://play.google.com/store/apps/details?id=org.techtown.pple&pli=1'
          }
          target="_blank"
        >
          <PlayStoreIcon width={width} height={height} />
          Google Play
        </a>
      </DownButton>
    </FlexDiv>
  );
};

const FlexDiv = styled.div<Props>`
  margin-top: ${(props) => props.marginTop};
  display: ${(props) => (props.isColumn ? 'block' : 'flex')};
  button:first-child {
    margin-right: 10px;
  }
  gap: 10px;
  @media ${devices.tablet} {
    justify-content: space-between;
    display: ${(props) => (props.isMobileColumn ? 'block' : 'flex')};
    button:first-child {
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
`;

const DownButton = styled.button<Props>`
  background: #222222;
  opacity: 0.8;
  border-radius: 10px;
  width: 145px;
  text-align: center;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.isColumn ? '10px' : '0')};
  padding: 4px 0px;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  position: relative;
  z-index: 99999999999999999999;
  @media ${devices.tablet} {
    /* line-height: 15px; */
    margin-top: 10px;
    width: ${(props) => (props.isMobileModal === true ? '100px' : '70%')};
    margin: 0 auto;
    height: 43px;
  }
`;

const AppStoreIcon = styled(AppleSvg)`
  margin-right: 5px;
`;

const PlayStoreIcon = styled(PlayStoreSvg)`
  margin-right: 5px;
`;

export default StoreBtn;
