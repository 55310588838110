import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
const CMToast = () => {
  return <Toast autoClose={1000} hideProgressBar />;
};

const Toast = styled(ToastContainer)`
  .Toastify__toast--success {
    background-color: #4caf50;
    color: #fff;
  }

  .Toastify__toast--error {
    background-color: #f44336;
    color: #fff;
  }

  .Toastify__toast--warning {
    background-color: #ff9800;
    color: #fff;
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.commonColor.pink004};
    color: #fff;
  }
`;

export default CMToast;
