import { IUser, RequestSignUp, ResponseCheckAuth } from '@src/interfaces/Auth';
import { authKeys } from '@src/keys/authKey';
import { auth, my } from '@src/services/api';
import {
  useMutation,
  useQuery,
  UseQueryResult,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import useToast from '@src/hooks/useToast';
import { getCookie } from 'cookies-next';
import { myKeys } from '@src/keys/myKey';

const useAuth = () => {
  //TODO useMutation().mutate로 데이터빼서 return 값줘보기
  const { showToast } = useToast();

  const fetchAuthentication = (
    phoneNumber: string
  ): UseMutationResult<any, AxiosError> => {
    return useMutation(() => auth.authentication(phoneNumber));
  };

  const fetchCheckAuthenication = (
    phoneNumber: string
  ): UseMutationResult<ResponseCheckAuth, AxiosError> => {
    return useMutation(() => auth.checkAuthentication(phoneNumber));
  };

  const signUp = (
    requestData: RequestSignUp
  ): UseMutationResult<any, AxiosError> => {
    return useMutation(() => auth.signUp(requestData));
  };

  const fetchProfile = (): UseQueryResult<IUser, AxiosError> => {
    const token = getCookie('X-AUTH-TOKEN');
    return useQuery(authKeys.profile, auth.getProfile, {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!token,
    });
  };

  const fetchProfileSummary = (): UseQueryResult<IUser, AxiosError> => {
    const token = getCookie('X-AUTH-TOKEN');
    return useQuery(myKeys.myProfileSummary, my.getMyProfileSummary, {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!token,
    });
  };

  const updateProfile = (): UseMutationResult<any, AxiosError> => {
    const queryClient = useQueryClient();
    const router = useRouter();
    return useMutation(async (formData: any) => {
      const res: any = await auth.updateProfile(formData);
      if (res.status === 200) {
        queryClient.invalidateQueries(authKeys.profile);
        router.push('my?category=profile');
      } else {
        showToast('info', '수정을 실패하였습니다.');
      }
    });
  };

  return {
    fetchAuthentication,
    fetchCheckAuthenication,
    signUp,
    fetchProfile,
    updateProfile,
    fetchProfileSummary,
  };
};

export default useAuth;
