import styled from 'styled-components';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { devices } from '@src/styles/theme';
type Props = {
  onScroll: () => void;
};
const FloatingScrollButton = ({ onScroll }: Props) => {
  return (
    <Container onClick={onScroll}>
      <Button>
        <ArrowIcon width={10} height={17} />
      </Button>
    </Container>
  );
};

export default FloatingScrollButton;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  right: 100px;
  z-index: 99999999999999999;
  @media ${devices.tablet} {
    right: 0px;
  }
  & > * {
    margin-right: 10px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  cursor: pointer;
  box-shadow: rgba(63, 71, 77, 0.25) 0px 2px 10px;

  background-color: ${({ theme }) => theme.commonColor.pink004};
`;

const ArrowIcon = styled(AiOutlineArrowUp)`
  color: ${({ theme }) => theme.commonColor.white};
`;
