import { devices } from '@src/styles/theme';
import Link from 'next/link';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Wrap>
            <Title>에러페이지</Title>
            <br />
            <Content>알 수 없는 에러가 발생했습니다..</Content>
            <Description>
              계속하여 해당 페이지가 나타나는 경우 피플 개발팀에 문의해주세요.
              <br /> 감사합니다.
              <a href="https://open.kakao.com/o/ggF0yWoe">개발팀 오픈톡</a>
            </Description>
            <Home>
              <Link href={'/'}>피플 홈 가기</Link>
            </Home>
          </Wrap>
        </Container>
      );
    }

    return this.props.children;
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Wrap = styled.section`
  text-align: center;
`;

const Title = styled.strong`
  font-size: 34px;
  font-weight: 700;
  @media ${devices.tablet} {
    font-size: 24px;
  }
`;

const Content = styled.strong`
  display: inline-block;
  margin: 24px 0px 16px 0px;
  font-weight: 600;
  font-size: 30px;
  @media ${devices.tablet} {
    font-size: 16px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.commonColor.gray005};
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.6px;
  line-height: 32px;
`;

const Home = styled.button`
  background-color: ${({ theme }) => theme.commonColor.pink001};
  border-radius: 5px;
  width: 100px;
  height: 50px;
  color: ${({ theme }) => theme.commonColor.pink004};
  cursor: pointer;
  margin-top: 18px;
`;

export default ErrorBoundary;
