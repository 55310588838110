import { devices } from '@src/styles/theme';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import MobileTabNav from '@src/components/Organism/Header/MobileTabNav';
import NavLeft from '@src/components/Organism/Header/NavLeft';
import NavRight from '@src/components/Organism/Header/NavRight';

const Header = () => {
  const [isClick, setIsClick] = useState(false);

  const onSetIsClick = useCallback((value: boolean) => {
    setIsClick(value);
  }, []);

  return (
    <Container>
      <HeaderWrap>
        <NavLeft />
        <NavRight setIsClick={onSetIsClick} />
        <MobileTabNav isClick={isClick} setIsClick={onSetIsClick} />
      </HeaderWrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 1.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.commonColor.gray002};
  background-color: #fff;
  @media ${devices.tablet} {
    display: flex;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9999999;
    background-color: #fff;
  }
`;

export default Header;
