import { requestAPI } from '@src/utils/fetch';
import { getCookie } from 'cookies-next';

const getMyCommunity = async (communityType: string) => {
  if (communityType === 'donation') {
    const { data } = await requestAPI().get(`/api/v2/donation/own`);
    return data;
  } else {
    const { data } = await requestAPI().get(`/api/v1/board/own`);
    return data;
  }
};
/**
 * 프로필 조회를 하나 필요한 정보를 요약해서 전달받는 함수이다.
 * @returns
 */

const getMyProfileSummary = async () => {
  const { data } = await requestAPI().get(`/api/v1/account/profile/summary`);
  return data;
};

const getMyBadgeList = async () => {
  const { data } = await requestAPI().get(`/api/v1/account/badges`);
  return data;
};

/**
 * 포인트샵 기프티콘을 조회하는 함수이다.
 * @returns
 */

const getShopGift = async () => {
  const { data } = await requestAPI().get(`/api/v1/shop/point`);
  return data;
};

const getMyGift = async () => {
  const { data } = await requestAPI().get(`/api/v1/shop/point/my`);
  return data;
};

const buyGift = async (request: string) => {
  const { data } = await requestAPI().post(
    `/api/v1/gift`,
    request,
    'multipart/form-data'
  );
  return data;
};

const getMyThanksLetters = async (accountUuid: string) => {
  const { data } = await requestAPI().get(
    `/api/v2/letter/thanks/${accountUuid}`
  );
  return data;
};

const getMyDonationStoryList = async () => {
  const { data } = await requestAPI().get('/api/v1/certificate/images');
  return data;
};

const myApiList = {
  getMyCommunity,
  getMyProfileSummary,
  getMyBadgeList,
  getShopGift,
  getMyGift,
  buyGift,
  getMyThanksLetters,
  getMyDonationStoryList,
};

export default myApiList;
