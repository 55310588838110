import { INITIAL_PROFILE } from '@src/data/Auth/profile';
import {
  requestAppleUuid,
  RequestSignUp,
  ResponseCheckAuth,
} from '@src/interfaces/Auth';
import { requestAPI } from '@src/utils/fetch';
import { AxiosError } from 'axios';

const authApiUrl = '/api/v1/account';

const authentication = async (phoneNumber: string) => {
  const requset = { phoneNumber };
  try {
    await requestAPI().post(`${authApiUrl}/authentication`, requset);

    return;
  } catch (e: any) {
    throw new Error(e);
  }
};

const checkAuthentication = async (phoneNumber: string) => {
  try {
    const data = await requestAPI().get(
      `${authApiUrl}/authentication?number=${phoneNumber}`
    );
    return data;
  } catch (e: any) {
    throw e;
  }
};

const signUp = async (requestData: RequestSignUp) => {
  try {
    const data = await requestAPI().post(`${authApiUrl}/info`, requestData);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getProfile = async () => {
  try {
    const { data } = await requestAPI().get(`${authApiUrl}/profile`);
    return data;
  } catch (e: any) {
    console.log(e);
  }
};

const updateProfile = async (formData: any) => {
  try {
    const data = await requestAPI().patch(
      `/api/v1/account/profile`,
      formData,
      'multipart/form-data'
    );
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const appleSignUp = async (request: requestAppleUuid) => {
  const postData = {
    OauthUuid: request,
  };
  try {
    const { data } = await requestAPI().post('/api/v2/auth/apple', postData);
    return data;
  } catch (e: any) {
    console.log(e, ',eerr');
    throw new Error(e);
  }
};

const authApiList = {
  authentication,
  checkAuthentication,
  signUp,
  getProfile,
  updateProfile,
  appleSignUp,
};

export default authApiList;
