import { useEffect, useState } from 'react';

const useOSDetect = (): string => {
  const [OS, setOS] = useState('');

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      setOS('android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setOS('ios');
    }
  }, []);

  return OS;
};
export default useOSDetect;
