import { devices } from '@src/styles/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { navList } from '@src/data/nav';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  isClick: boolean;
  setIsClick: (v: any) => void;
};
/**
 *
 * @param param0
 * @returns 모바일 환경에서 햄버거 메뉴리스트이다.
 */
const MobileTabNav = ({ isClick, setIsClick }: Props) => {
  const router = useRouter();
  return (
    <Container isClick={isClick}>
      <TabUl>
        {navList.map((menu, i) => (
          <TabLi key={i} isClick={menu.url === router.pathname}>
            {menu.name === '피플샵' ? (
              <a
                href={menu.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  sessionStorage.removeItem('community_scroll');
                  setIsClick(!isClick);
                }}
              >
                {menu.name}
              </a>
            ) : (
              <Link
                onClick={() => {
                  sessionStorage.removeItem('community_scroll');
                  setIsClick(!isClick);
                }}
                href={menu.url}
              >
                {menu.name}
              </Link>
            )}
          </TabLi>
        ))}
      </TabUl>
    </Container>
  );
};

const Container = styled.nav<{ isClick: boolean }>`
  display: none;
  pointer-events: none;
  @media ${devices.tablet} {
    position: absolute;
    top: 63px;
    right: 0px;
    display: block;
    width: 100%;
    background-color: #fff;
    z-index: 999999999;
    opacity: ${({ isClick }) => (isClick ? 1 : 0)};
    transform: translateY(0);
    transition: all 0.3s;
    pointer-events: ${({ isClick }) => (isClick ? 'auto' : 'none')};
  }
`;

const TabUl = styled.ul`
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const TabLi = styled.li<{ isClick?: boolean }>`
  @media ${devices.tablet} {
    display: inline-block;
    margin: 0;
    a {
      text-decoration: none;
      margin-bottom: -1px;
      border-bottom: 1px solid #ccc;
      padding: 1.6rem;
      display: block;
      opacity: 1;
      transition: opacity 0.3s;
      font-size: 1rem;
    }
  }
  color: ${({ isClick, theme }) =>
    isClick ? theme.commonColor.pink004 : '#000'};
  font-weight: 500;
  z-index: 999999999;
`;

export default MobileTabNav;
