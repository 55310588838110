import {
  requestComment,
  requestDonation,
  requestShare,
} from '@src/interfaces/community';
import { requestAPI } from '@src/utils/fetch';

const getDonations = async (
  page: number,
  keyword?: string,
  abo?: string,
  bloodProduct?: string,
  sortBy?: string
) => {
  const { data } = await requestAPI().get(
    `/api/v3/donation/search?page=${page + 1}&keyword=${keyword || ''}&abo=${
      abo || ''
    }&bloodProduct=${bloodProduct || ''}&sortBy=${sortBy || ''}
    `
  );
  return data;
};

const getBoards = async (page: number, keyword?: string) => {
  const { data } = await requestAPI().get(
    `/api/v1/board/search?page=${page + 1}&keyword=${keyword}`
  );
  return data;
};

const getBoard = async (id: string) => {
  const { data } = await requestAPI().get(`/api/v1/board/${id}`);
  return data;
};

const getDonation = async (id: string) => {
  const { data } = await requestAPI().get(`/api/v1/donation/${id}`);
  return data;
};

const toggleLike = async (id: string, communityType: string) => {
  if (communityType === 'donation') {
    const { data } = await requestAPI().post(`/api/v1/donation/like/${id}`);
    return data;
  } else {
    const { data } = await requestAPI().post(`/api/v1/board/like/${id}`);
    return data;
  }
};

const insertDonation = async (request: requestDonation) => {
  const data = await requestAPI().post(
    `/api/v2/donation`,
    request,
    'multipart/form-data'
  );
  return data;
};
const insertBoard = async (request: any) => {
  const data = await requestAPI().post(
    `/api/v1/board`,
    request,
    'multipart/form-data'
  );
  return data;
};

const updateDonation = async (request: requestDonation) => {
  const body = {
    bloodProduct: request.bloodProduct,
    content: request.content,
    patient: request.patient,
    title: request.title,
    phoneNumber: request.phoneNumber,
  };
  const data = await requestAPI().patch(`/api/v1/donation/${request.id}`, body);
  return data;
};

const updateBoard = async (request: any) => {
  const data = await requestAPI().patch(`/api/v1/board`, request);
  return data;
};

const insertComment = async (body: requestComment, communityType: string) => {
  if (communityType === 'donation') {
    const data = await requestAPI().post(
      'api/v1/donation/reply',
      body.requestData
    );
    return data;
  } else {
    const data = await requestAPI().post(
      'api/v1/board/reply',
      body.requestData
    );
    return data;
  }
};

const insertReplyComment = async (
  body: requestComment,
  communityType: string
) => {
  if (communityType === 'donation') {
    const data = await requestAPI().post(
      'api/v1/donation/reply/reply',
      body.requestData
    );
    return data;
  } else {
    const data = await requestAPI().post(
      'api/v1/board/reply/reply',
      body.requestData
    );
    return data;
  }
};

const deleteCommunity = async (id: string, communityType: string) => {
  if (communityType === 'donation') {
    const data = await requestAPI().delete(`/api/v1/donation/delete/${id}`);
    return data;
  } else {
    const data = await requestAPI().delete(`/api/v1/board/${id}`);
    return data;
  }
};

const updateReplyComment = async (
  body: requestComment,
  communityType: string
) => {
  if (communityType === 'donation') {
    const data = await requestAPI().patch(
      `/api/v1/donation/reply/reply`,
      body.requestData
    );
    return data;
  } else {
    const data = await requestAPI().patch(
      `/api/v1/donation/reply/reply`,
      body.requestData
    );
    return data;
  }
};

const updateComment = async (body: requestComment, communityType: string) => {
  if (communityType === 'donation') {
    const data = await requestAPI().patch(
      `/api/v1/donation/reply`,
      body.requestData
    );
    return data;
  } else {
    const data = await requestAPI().patch(
      `/api/v1/donation/reply`,
      body.requestData
    );
    return data;
  }
};

const deleteComment = async (id: string, communityType: string) => {
  if (communityType === 'donation') {
    const data = await requestAPI().delete(`/api/v1/donation/reply/${id}`);
    return data;
  } else {
    const data = await requestAPI().delete(`/api/v1/board/reply/${id}`);
    return data;
  }
};

const deleteReplyComment = async (id: string, communityType: string) => {
  if (communityType === 'donation') {
    const data = await requestAPI().delete(
      `/api/v1/donation/reply/reply/${id}`
    );
    return data;
  } else {
    const data = await requestAPI().delete(`/api/v1/board/reply/reply/${id}`);
    return data;
  }
};

const postShareDonation = async (body: requestShare) => {
  const { data } = await requestAPI().post(`/api/v1/donation/url`, body);
  return data;
};

const encodedGetDonation = async (id: string) => {
  const { data } = await requestAPI().get(`/api/v1/donation/detail/${id}`);
  return data;
};
const communityApiList = {
  getDonations,
  getBoards,
  getBoard,
  getDonation,
  toggleLike,
  insertDonation,
  insertBoard,
  insertComment,
  insertReplyComment,
  updateDonation,
  updateBoard,
  deleteCommunity,
  updateReplyComment,
  updateComment,
  deleteComment,
  deleteReplyComment,
  postShareDonation,
  encodedGetDonation,
};

export default communityApiList;
