import { HiDownload } from 'react-icons/hi';
import React from 'react';
import { useRouter } from 'next/router';
import { devices, theme } from '@src/styles/theme';
import { GiHamburgerMenu } from 'react-icons/gi';
import styled from 'styled-components';
import useOSDetect from '@src/hooks/useOsDetect';
import * as gtag from '@src/utils/gtag';
import ProfileWrap from './ProfileWrap';

type Props = {
  setIsClick: (v: any) => void;
};

const NavRight = ({ setIsClick }: Props) => {
  const isMobileOs = useOSDetect();

  const onAnchorStore = () => {
    gtag.download('');
    if (isMobileOs === 'android') {
      window.open(
        'https://play.google.com/store/apps/details?id=org.techtown.pple&pli=1'
      );
    } else {
      window.open(
        'https://apps.apple.com/kr/app/%ED%94%BC%ED%94%8C/id1621815801'
      );
    }
  };

  return (
    <>
      <HeaderNavRight>
        <HeaderUl>
          <InfoWrap>
            <ProfileWrap />
            <Bar />
            <DownLoadWrap onClick={onAnchorStore}>
              <div>
                <DownloadIcon />
              </div>
              <div
                style={{
                  marginLeft: 8,
                }}
              >
                앱 다운로드
              </div>
            </DownLoadWrap>
          </InfoWrap>
        </HeaderUl>
        <HamburgerWrap>
          <Hamburger
            onClick={() => {
              setIsClick((prev: any) => !prev);
            }}
          />
        </HamburgerWrap>
      </HeaderNavRight>
    </>
  );
};

const HamburgerWrap = styled.div`
  display: none;
  @media ${devices.tablet} {
    display: block;
    width: 25%;
  }
`;

const DownLoadWrap = styled.div`
  color: ${({ theme }) => theme.commonColor.pink004};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    display: none;
  }
`;

const Hamburger = styled(GiHamburgerMenu)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const DownloadIcon = styled(HiDownload)`
  & > path {
    fill: ${({ theme }) => theme.commonColor.pink004};
  }
`;

const Bar = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.commonColor.gray003};
  @media ${devices.tablet} {
    display: none;
  }
`;

const HeaderNavRight = styled.nav`
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    width: 100%;
  }
`;

const HeaderUl = styled.ul`
  display: flex;
  @media ${devices.tablet} {
    justify-content: space-between;
    flex: 1;
    align-items: center;
    font-size: 14px;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.2rem;
  gap: 0.7rem;
`;

export default NavRight;
