export const bloodList = [
  { name: 'A+', value: 'A' },
  { name: 'B+', value: 'B' },
  { name: 'AB+', value: 'AB' },
  { name: 'O+', value: 'O' },
];

export const bloodDonationTypeList = [
  {
    name: '전혈(WB)',
    description: `가장 일반적인 헌혈로 백혈구를 제외한 혈액을 의미합니다.
  문진과 휴식을 포함해 30~40분 정도 소요되며, 
  실질적인 헌혈 시간은 10분 가량입니다.
  320ml 또는 400ml를 채혈하게 되며, 전혈 헌혈을 하고 나면 8주간 헌혈이 불가능 합니다.`,
  },
  {
    name: '농축적혈구(RBC)',
    description: '전혈에서 현장 성분을 제거한 적혈구 혈액제제입니다.',
  },
  {
    name: '백혈구여과제거적혈구(F-RBC)',
    description:
      '농축 적혈구로부터 백혈구 제거용 필터를 이용하여 백혈구를 제거한 성분제제입니다.',
  },
  {
    name: '신선동결혈장(FFP)',
    description:
      '채혈후 6시간 이내에 분리한 혈장을 동결시킨 혈액성분제제로 각종 혈액응고인자가 보존된 혈장',
  },
  {
    name: '성분채혈 혈소판(A-PLT(단종),A-PLT[M](다종))',
    description:
      '혈장 내에 있는 혈소판을 채집하는 방식으로 한 단위 기준 250ml를 채집합니다.채혈 시간이 약 1시간 ~ 1시간 반으로 비교적 긴 편이며 2주에 한번 헌혈이 가능합니다.',
  },
];
