import ErrorBoundary from '@src/components/ErrorBoundary';
import Modal from '@src/components/Organism/Modal';
import Header from '@src/components/Template/Header';
import GlobalStyle from '@src/styles/global-styles';
import { theme } from '@src/styles/theme';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import '@src/styles/font.css';
import Footer from '@src/components/Footer';
import CMToast from '@src/components/Atoms/CMToast';
import { useRouter } from 'next/router';
import * as gtag from '@src/utils/gtag';
import Script from 'next/script';
import FloatingKaKaoButton from '@src/components/Atoms/FloatingKaKaoButton';
import useWindowSize from '@src/hooks/useWindowSize';
import useScrollTop from '@src/hooks/useScrollTop';
import FloatingScrollButton from '@src/components/Atoms/FloatingScrollButton';

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { height } = useWindowSize();
  const CURRENT_Y = Math.floor(height / 1.5);
  const { showButton, scrollToTop } = useScrollTop(CURRENT_Y);
  // ga page변경 측정
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      location.protocol !== 'https:'
    ) {
      router.replace(`https://${location.hostname}${location.pathname}`);
    }
    const handleRouteChange = (url: string) => {
      if (
        !url.includes('detail') &&
        !url.includes('post') &&
        !url.includes('my')
      ) {
        // 상세 페이지는 측정하지 않는다.
        console.log('페이지 전환측정');
        gtag.pageview(url, document.title);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router]);
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // 여기서 networkMode를 설정합니다.
            networkMode: 'always',
          },
        },
      })
  );
  if (process.env.NODE_ENV === 'production') {
    if (typeof window !== 'undefined') {
      if (window.location.href.includes('link')) {
        console.log = () => {};
      }
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Hydrate state={pageProps.dehydratedState}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <GlobalStyle />
              <Modal />
              <Header />
              {/* GA 설정 시작 */}
              <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
              />
              <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtag.GA_TRACKING_ID}', {
          page_path: window.location.pathname,
        });
      `,
                }}
              />
              {/* GA 설정 끝 */}
              {/* META PIXEL 설정 시작*/}
              <Script
                id="fb-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_META_ID}');
fbq('track', 'PageView');`,
                }}
              />
              <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_GTM_TAG_ID}');`,
                }}
              />
              <Component {...pageProps} />
              {/* <FloatingKaKaoButton /> */}
              {showButton && <FloatingScrollButton onScroll={scrollToTop} />}
              <CMToast />
              <Footer />
            </ErrorBoundary>
          </ThemeProvider>
        </RecoilRoot>
      </Hydrate>
    </QueryClientProvider>
  );
}
