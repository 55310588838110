import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    commonColor: {
      black: '#222222';
      white: '#ffffff';
      gray001: '#f9f9f9';
      gray002: '#F4F4F4';
      gray003: '#D8D8D8';
      gray004: '#D7D7D7';
      gray005: '#767676';
      gray006: '#c1c1c1';
      gray007: '#B7B7B7';
      pink001: '#FFF1ED';
      pink002: '#FFEDE8';
      pink003: '#FFA3A3';
      pink004: '#FF6969';
      pink005: '#FF4651';
      yellow001: '#FFF8DE';
      yellow002: '#FFC300';
    };
  }
}

export const theme: DefaultTheme = {
  commonColor: {
    black: '#222222',
    white: '#ffffff',
    gray001: '#f9f9f9',
    gray002: '#F4F4F4',
    gray003: '#D8D8D8',
    gray004: '#D7D7D7',
    gray005: '#767676',
    gray006: '#c1c1c1',
    gray007: '#B7B7B7',
    pink001: '#FFF1ED',
    pink002: '#FFEDE8',
    pink003: '#FFA3A3',
    pink004: '#FF6969',
    pink005: '#FF4651',
    yellow001: '#FFF8DE',
    yellow002: '#FFC300',
  },
};

export const sizes = {
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};
