import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import { devices } from './theme';

const GlobalStyle = createGlobalStyle`
      ${reset}
      * {
        box-sizing: border-box;
        font-family: "Regular";
      }
      html {
        height: 100%;
      }
      body{
        margin:0;
        padding:0;
        background-color: #f9f9f9;
        font-family: "Regular";
        @media ${devices.tablet} {
          background-color:#fff;
        }
        height: 100%;
        > #__next {
            height: 100%;
        }
      }
      a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
      }
      input, button {
        background-color: transparent;
        border: none;
        outline: none;
      }
      h1, h2, h3, h4, h5, h6{
        font-family:'Maven Pro', sans-serif;
      }
      div {
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
      }
      ol {
        list-style: none;
      }
      .swiper-pagination-bullet-active {
        background-color: #FF6969 !important;
      }
      .highlight{
        background-color: transparent !important;
        color: ${({ theme }) => theme.commonColor.pink004} !important;
        font-weight:700;
      }
      .swiper-button-prev,
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  color: #fff !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  text-align: center;
}
      
    `;

export default GlobalStyle;
