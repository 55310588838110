import { devices } from '@src/styles/theme';
import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import HeaderLogoSvg from '@src/assets/imgs/headerLogo.svg';
import { navList } from '@src/data/nav';
import Link from 'next/link';
import { TbPencil } from 'react-icons/tb';
import { useRouter } from 'next/router';
import { useBodyScrollLock } from '@src/hooks/useBodyScrollLock';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalAtom } from '@src/recoils/atom/modal';
import keywordAtom from '@src/recoils/atom/keyword';
import profileAtom from '@src/recoils/atom/profile';

const NavLeft = () => {
  const router = useRouter();
  const { lockScroll } = useBodyScrollLock();
  const setModal = useSetRecoilState(modalAtom);
  const setKeyword = useSetRecoilState(keywordAtom);
  const profile = useRecoilValue(profileAtom);

  const onEdit = () => {
    if (profile.isLoggedIn === false) {
      lockScroll();
      setModal({
        id: 'unAuthorized',
        visible: true,
      });
      return;
    } else {
      lockScroll();
      setModal({
        id: 'selectCommunity',
        visible: true,
      });
    }
  };
  return (
    <HeaderNavLeft>
      <HeaderUl>
        <Link
          href="/"
          style={{
            marginRight: '4rem',
          }}
        >
          <HeaderLogoIcon />
        </Link>
        {navList.map((menu, i) => (
          <Menu isClick={menu.url === router.pathname} key={i}>
            {menu.name === '피플샵' ? (
              <a
                href={menu.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  sessionStorage.removeItem('community_scroll');
                }}
              >
                {menu.name}
              </a>
            ) : (
              <Link
                onClick={() => {
                  sessionStorage.removeItem('community_scroll');
                  setKeyword('');
                }}
                href={menu.url}
              >
                {menu.name}
              </Link>
            )}
          </Menu>
        ))}
        {(router.pathname.startsWith('/community/board') ||
          router.pathname.startsWith('/community/donation')) && (
          <CommunityInsert onClick={onEdit} />
        )}
      </HeaderUl>
    </HeaderNavLeft>
  );
};

const HeaderNavLeft = styled.nav`
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    width: 100%;
  }
`;

const HeaderUl = styled.ul`
  display: flex;
  position: relative;
  @media ${devices.tablet} {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 20px;
  }
`;

const Menu = styled.li<{ isClick?: boolean }>`
  cursor: pointer;
  margin-right: 2rem;
  line-height: 28px;
  color: ${({ isClick, theme }) =>
    isClick ? theme.commonColor.pink004 : '#000'};
  font-weight: 500;
  &:hover {
    color: ${({ theme, isClick }) =>
      isClick ? theme.commonColor.pink004 : theme.commonColor.gray006};
  }
  @media ${devices.tablet} {
    display: none;
  }
`;

const HeaderLogoIcon = styled(HeaderLogoSvg)``;

const CommunityInsert = styled(TbPencil)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
  position: absolute;
  right: 60px;
  width: 24px;
  height: 24px;
`;

export default NavLeft;
