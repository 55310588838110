import { modalAtom } from '@src/recoils/atom/modal';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import AppDownModal from '@src/components/Organism/Modal/AppDownModal';
import BloodDonationTypeModal from '@src/components/Organism/Modal/BloodDonationTypeModal';
import UnAuthorizedModal from '@src/components/Organism/Modal/UnAuthorizedModal';
import SelectCommunityModal from '@src/components/Organism/Modal/SelectCommunityModal';
import { useBodyScrollLock } from '@src/hooks/useBodyScrollLock';
import PointModal from '@src/components/Organism/Modal/PointModal';
import SelectKaKaoModal from './SelectKaKaoModal';

const Modal = () => {
  const [modal, setModal] = useRecoilState(modalAtom);
  const { openScroll } = useBodyScrollLock();
  const closeModal = useCallback(() => {
    setModal({ ...modal, visible: false });
    openScroll();
  }, [modal, setModal]);

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      {modal.id === 'appDown' && modal.visible && (
        <AppDownModal onClose={closeModal} stopPropagation={stopPropagation} />
      )}
      {modal.id === 'bloodDonationType' && modal.visible && (
        <BloodDonationTypeModal
          onClose={closeModal}
          stopPropagation={stopPropagation}
        />
      )}
      {modal.id === 'unAuthorized' && modal.visible && (
        <UnAuthorizedModal
          onClose={closeModal}
          stopPropagation={stopPropagation}
        />
      )}
      {modal.id === 'selectCommunity' && modal.visible && (
        <SelectCommunityModal
          onClose={closeModal}
          stopPropagation={stopPropagation}
        />
      )}
      {modal.id === 'point' && modal.visible && (
        <PointModal onClose={closeModal} stopPropagation={stopPropagation} />
      )}
      {modal.id === 'selectKaKao' && modal.visible && (
        <SelectKaKaoModal
          onClose={closeModal}
          stopPropagation={stopPropagation}
        />
      )}
    </>
  );
};

export default Modal;
