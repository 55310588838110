import React from 'react';
import styled from 'styled-components';

type Props = {
  height: string;
  width: string;
  src: string;
  borderRadius?: string;
};

const CMUserProfile = ({ height, width, src, borderRadius }: Props) => {
  return (
    <ProfileIcon
      height={height}
      width={width}
      src={src}
      borderRadius={borderRadius}
    />
  );
};

const ProfileIcon = styled.img<Props>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  src: ${({ src }) => src};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export default CMUserProfile;
