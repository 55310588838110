import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { devices, theme } from '@src/styles/theme';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import Link from 'next/link';
import PangSvg from '@src/assets/imgs/pang.svg';
import PointSvg from '@src/assets/imgs/point.svg';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
};

const PointModal = ({ onClose, stopPropagation, width, height }: Props) => {
  return (
    <ModalContainer
      width={480}
      height={300}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        <Header>
          <PangSvg />
          <PointIcon />
        </Header>
        <Content>
          <HeaderDescription>
            사연공유로 100포인트가 적립되었어요!
          </HeaderDescription>
        </Content>
        <ShareDescription>
          공유로 더 많은 사람들이 유입되면, 400포인트가 추가 지급돼요!
        </ShareDescription>
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
  margin-top: 80px;

  @media ${devices.tablet} {
    margin-top: 70px;
  }
`;

const HeaderDescription = styled(CMText)`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
`;

const PointIcon = styled(PointSvg)`
  width: 120px;
  height: 90px;
  position: absolute;
  top: 10px;
`;

const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

const ShareDescription = styled.div`
  margin-top: 30px;
  padding: 16px 30px;
  background: #ffede8;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 250px;
  color: #767676;
`;

export default PointModal;
