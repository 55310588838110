import Link from 'next/link';
import styled from 'styled-components';
import AppleSvg from '@src/assets/imgs/appleIcon.svg';
import PlayStoreSvg from '@src/assets/imgs/playStoreIcon.svg';
import InstaSvg from '@src/assets/imgs/instaIcon.svg';
import BlogSvg from '@src/assets/imgs/blog.svg';
import { devices } from '@src/styles/theme';
import StoreBtn from './Molecules/Store/StoreBtn';
import { useRouter } from 'next/router';
// TODO: 푸터 구조개선
const Footer = () => {
  const router = useRouter();
  return (
    <>
      {router.pathname !== '/404' && router.pathname !== '/auth' && (
        <Container>
          <Wrap>
            <IntroduceSection>
              <FlexBox>
                <Nav>
                  <Ul>
                    <List>
                      <Link href={'/community/donation'}>헌혈요청</Link>
                    </List>
                    <List>
                      <Link href={'/community/board'}>커뮤니티</Link>
                    </List>
                    <List>
                      <Link href={'/'}>피플 소식</Link>
                    </List>
                  </Ul>
                  <Ul>
                    <List>
                      <Link
                        href={
                          'https://davidkim2028.notion.site/352761e3bfe74b6dbed9be29a474c0f3 '
                        }
                        target="_blank"
                      >
                        회사 소개
                      </Link>
                    </List>
                    <List>
                      <Link
                        href={
                          'https://davidkim2028.notion.site/a278c73a1e19446c8485c50265e0a6eb'
                        }
                        target="_blank"
                      >
                        인재 채용
                      </Link>
                    </List>
                  </Ul>
                  <Ul>
                    <List>
                      <Link href={'https://velog.io/@pple_dev'} target="_blank">
                        기술 블로그
                      </Link>
                    </List>
                  </Ul>
                </Nav>
                <App_SNS>
                  <Text>피플 앱 다운로드</Text>
                  <StoreBtn width={20} height={20} marginTop={'20px'} />
                  <MarginFlexSection isMargin={true} marginValue={'30px 0 0 0'}>
                    <Link
                      href={'https://www.instagram.com/team_pple/'}
                      target="_blank"
                    >
                      <InstaIcon width={24} height={24} />
                    </Link>
                    <Link
                      href={'https://blog.naver.com/contact_people'}
                      target="_blank"
                    >
                      <BlogSvg width={24} height={24} />
                    </Link>
                  </MarginFlexSection>
                </App_SNS>
              </FlexBox>
            </IntroduceSection>
            {/*  */}
            <IsPcBox>
              <DescriptionSection>
                <MarinFlexDiv>
                  <IntroCompanySpan>
                    <b>대표</b>
                    {'  '}
                    김범준
                  </IntroCompanySpan>
                  <IntroCompanySpan isMargin={true} marginValue={'10px'}>
                    {'  '}
                    <b>|</b>
                  </IntroCompanySpan>
                  <IntroCompanySpan>
                    <b>사업자번호</b> 166-88-02567
                  </IntroCompanySpan>
                  <Address isMargin={true} marginValue={'15px'}>
                    <div>
                      <b>주소</b>
                      {'  '}
                      서울특별시 마포구 월드컵북로6길 36
                    </div>
                    <div>
                      <b>고객센터</b>
                      {'  '}
                      pple.dev@gmail.com
                    </div>
                  </Address>
                </MarinFlexDiv>
                <PcSocialDiv>
                  <b>(주)피플즈</b> 보건복지부 예비사회적기업 지정번호 :
                  보건복지부 제 2023-36호
                </PcSocialDiv>
                <IntroCompanySpan>
                  시민여러분들의 소중한 후원으로 만들어지고 있는 피플입니다
                </IntroCompanySpan>
              </DescriptionSection>
            </IsPcBox>
            <IsMobileBox>
              <DescriptionSection>
                <>
                  <IntroCompanySpan>
                    <b>대표</b>
                    {'  '}
                    김범준
                  </IntroCompanySpan>
                  <IntroCompanySpan isMargin={true} marginValue={'10px'}>
                    {'  '}
                    <b>|</b>
                  </IntroCompanySpan>
                  <IntroCompanySpan>
                    <b>사업자번호</b> 166-88-02567
                  </IntroCompanySpan>
                  <Address>
                    <MobileDiv>
                      <b>주소</b>
                      {'  '}
                      서울특별시 마포구 월드컵북로6길 36
                    </MobileDiv>
                  </Address>
                  <MobileDiv>
                    <b>고객센터</b>
                    {'  '}
                    pple.dev@gmail.com
                  </MobileDiv>
                  <PcSocialDiv>
                    <b>(주)피플즈</b> 보건복지부 예비사회적기업
                    <br /> 지정번호 : 보건복지부 제 2023-36호
                  </PcSocialDiv>
                  <IntroCompanySpan>
                    시민여러분들의 소중한 후원으로
                    <br />
                    만들어지고 있는 피플입니다
                  </IntroCompanySpan>
                </>
              </DescriptionSection>
            </IsMobileBox>
          </Wrap>
        </Container>
      )}
    </>
  );
};

export default Footer;

const Container = styled.footer`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media ${devices.tablet} {
    max-width: 100%;
    padding: 0 1.6rem;
  }
  background-color: ${({ theme }) => theme.commonColor.gray001};
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 76.8rem;
  margin: 0 auto;
`;

const IntroduceSection = styled.section`
  padding: 4.8rem 0;
  @media ${devices.tablet} {
    max-width: 100%;
    padding: 0;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${devices.tablet} {
    display: block;
  }
`;

const App_SNS = styled.section`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    border-top: 1px solid ${({ theme }) => theme.commonColor.gray004};
    padding: 1rem 0;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  &:nth-last-child() {
    margin-right: 30%;
  }
`;

const List = styled.li`
  margin: 1.6rem;
  cursor: pointer;
  @media ${devices.tablet} {
    font-size: 12px;
  }
`;

const Nav = styled.nav`
  display: flex;
  ul:first-child > li {
    margin: 1.6rem 1.6rem 1.6rem 0;
  }
  @media ${devices.tablet} {
    ul:first-child > li {
      margin: 1.6rem;
    }
  }
`;

const FlexDiv = styled.div`
  margin-top: 20px;
  display: flex;
  button:first-child {
    margin-right: 20px;
  }
  @media ${devices.tablet} {
    justify-content: space-between;
  }
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: ${({ theme }) => theme.commonColor.black};
  @media ${devices.tablet} {
    font-size: 18px;
  }
`;

const AppStoreIcon = styled(AppleSvg)`
  margin-right: 5px;
  & > path {
    fill: ${({ theme }) => theme.commonColor.gray004};
  }
`;

const PlayStoreIcon = styled(PlayStoreSvg)`
  margin-right: 5px;
`;

const InstaIcon = styled(InstaSvg)`
  margin-right: 22px;
  & > path {
    fill: ${({ theme }) => theme.commonColor.gray004};
  }
`;

const BlogIcon = styled(BlogSvg)`
  margin-right: 22px;
  & > path {
    fill: ${({ theme }) => theme.commonColor.gray004};
  }
`;

const DownButton = styled.button`
  background: #222222;
  opacity: 0.8;
  border-radius: 10px;
  width: 147px;
  text-align: center;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media ${devices.tablet} {
    line-height: 15px;
  }
`;

const MarginFlexSection = styled.section<{
  isMargin?: boolean;
  marginValue?: string;
}>`
  margin: ${({ isMargin, marginValue }) => (isMargin ? marginValue : null)};
  display: flex;
  align-items: center;
`;

const MarinFlexDiv = styled.div<{ isMargin?: boolean; marginValue?: string }>`
  display: flex;
  align-items: center;
  margin: ${({ isMargin, marginValue }) => (isMargin ? marginValue : 0)};
  a:not(:last-child) {
    margin-right: 10px;
  }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.6px;
    color: ${({ theme }) => theme.commonColor.gray005};
  }
`;

const DescriptionSection = styled.section`
  padding: 2.4rem 0 10rem;
  border-top: 1px solid ${({ theme }) => theme.commonColor.gray004};
`;

const Address = styled.address<{ isMargin?: boolean; marginValue?: string }>`
  b {
    font-weight: 700;
  }
  color: ${({ theme }) => theme.commonColor.gray005};
  margin: ${({ isMargin, marginValue }) => (isMargin ? marginValue : null)};
  display: flex;
  div:first-child {
    margin-right: 20px;
  }
`;

const IntroCompanySpan = styled.span<{
  isMargin?: boolean;
  marginValue?: string;
}>`
  b {
    font-weight: 700;
  }
  color: ${({ theme }) => theme.commonColor.gray005};
  margin: ${({ isMargin, marginValue }) => (isMargin ? marginValue : 0)};
`;

const IsPcBox = styled.div`
  display: block;
  @media ${devices.tablet} {
    display: none;
  }
`;

const IsMobileBox = styled.div`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const MobileDiv = styled.div<{ isBold?: boolean }>`
  margin: 8px 0;
  font-weight: ${({ isBold }) => (isBold ? 700 : '400')};
  color: ${({ theme }) => theme.commonColor.gray005};
  b {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.6px;
  }
`;

const PcSocialDiv = styled.div`
  b {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.6px;
  }
  margin-bottom: 4px;
  color: ${({ theme }) => theme.commonColor.gray005};
`;
