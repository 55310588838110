import styled from 'styled-components';
import React from 'react';

type Props = {
  className?: string;
  commonColor?: string;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  children?: React.ReactNode;
  lineHeight?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
};

//TO DO: Add font-family , Linkable Text(Optional)
const CMText = ({
  className,
  commonColor,
  fontSize = 16,
  fontWeight = 400,
  children,
  fontFamily,
  style,
  onClick,
  lineHeight,
}: Props) => {
  return (
    <Text
      className={className}
      style={style}
      commonColor={commonColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={onClick}
      lineHeight={lineHeight}
      fontFamily={fontFamily}
    >
      {children}
    </Text>
  );
};

/*
INFO: font-weight information
  normal : 400
  bold: 700
*/
const Text = styled.span<{
  fontSize: number;
  commonColor?: string;
  fontWeight: number;
  lineHeight?: number;
  fontFamily?: string;
}>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ commonColor, theme }) => commonColor ?? theme.commonColor.black};
  line-height: ${({ lineHeight }) => lineHeight}px;
`;

export default CMText;
