import CMText from '@src/components/Atoms/CMText';
import ModalContainer from '@src/components/Molecules/ModalContainer';
import { bloodDonationTypeList } from '@src/data/blood';
import { devices, theme } from '@src/styles/theme';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import { useCallback, useState } from 'react';
type Props = {
  onClose: () => void;
  stopPropagation: (e: any) => void;
  width?: number;
  height?: number;
};

const BloodDonationTypeModal = ({
  onClose,
  stopPropagation,
  width,
  height,
}: Props) => {
  const [isClick, setIsClick] = useState<any>(0);

  const onCLick = useCallback((i: number) => {
    setIsClick((prev: any) => (prev === i ? '' : i));
  }, []);
  return (
    <ModalContainer
      width={520}
      height={460}
      onClose={onClose}
      stopPropagation={stopPropagation}
      backgroundColor={theme.commonColor.white}
    >
      <Container>
        <CloseModalButton onClick={onClose} />
        <Header>
          <HeaderTitle fontSize={22} fontWeight={700}>
            헌혈의 종류
          </HeaderTitle>
        </Header>
        <Content>
          {bloodDonationTypeList.map((blood, i) => (
            <>
              <Wrap
                onClick={() => {
                  onCLick(i);
                }}
              >
                <Line>
                  <Name isActive={isClick === i}>{blood.name}</Name>
                </Line>
                {isClick !== i ? (
                  <DownArrow isActive={isClick === i} />
                ) : (
                  <UpArrow isActive={isClick === i} />
                )}
              </Wrap>
              {isClick === i && (
                <HeaderDescription>{blood.description}</HeaderDescription>
              )}
            </>
          ))}
        </Content>
      </Container>
    </ModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.commonColor.gray005};
  letter-spacing: -0.6px;
`;

const HeaderTitle = styled(CMText)`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  text-align: center;
  letter-spacing: -0.6px;
  @media ${devices.tablet} {
    font-size: 16px;
  }
`;
const HeaderDescription = styled(CMText)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.commonColor.gray005};
  text-align: left;
  @media ${devices.tablet} {
    font-size: 12px;
  }
`;

const CloseModalButton = styled(AiOutlineClose)`
  width: 28px;
  height: 28px;
  margin-left: auto;
  color: ${({ theme }) => theme.commonColor.white};
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
`;

const Wrap = styled.div`
  width: 100%;
  padding: 15px 0px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.commonColor.gray002};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Line = styled.div`
  flex: 1;
`;

const Name = styled.div<{ isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.commonColor.pink004 : theme.commonColor.black};
  padding: 5px 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.6px;
`;

const UpArrow = styled(HiOutlineChevronUp)<{ isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.commonColor.pink004 : theme.commonColor.black};
`;
const DownArrow = styled(HiOutlineChevronDown)<{ isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.commonColor.pink004 : theme.commonColor.black};
`;

export default BloodDonationTypeModal;
