import { IRequestBloodHomeReview } from '@src/interfaces/blood';
import { requestAPI } from '@src/utils/fetch';

const getBloodReviews = async () => {
  try {
    const { data } = await requestAPI().get('/api/v1/review/search');
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getBloodHome = async (keyword?: string) => {
  try {
    const { data } = await requestAPI().get(
      `/api/v1/bh/search?keyword=${keyword}`
    );
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const createBloodHomeReview = async (request: IRequestBloodHomeReview) => {
  try {
    const data = await requestAPI().post(
      `/api/v1/bh/review`,
      request,
      'multipart/form-data'
    );
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const updateBloodHomeReview = async (
  request: IRequestBloodHomeReview,
  communityUuid: any
) => {
  try {
    const data = await requestAPI().patch(
      `/api/v1/bh/review/${communityUuid}`,
      request
    );
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getSelectBloodHomeReviews = async (
  bloodHomeUuid: string,
  page: number,
  keyword?: string
) => {
  try {
    const { data } = await requestAPI().get(
      `/api/v1/bh/${bloodHomeUuid}/reviews?page=${page + 1}&keyword=${keyword}`
    );
    return data;
  } catch (error) {}
};

const bloodApiList = {
  getBloodReviews,
  getBloodHome,
  createBloodHomeReview,
  updateBloodHomeReview,
  getSelectBloodHomeReviews,
};

export default bloodApiList;
